import React, { useState, useEffect } from "react";
import Table from "../../../table";
import { useAuth } from "../../../../context/auth-context";
import { useAsync } from "../../../../utils/use-async";
import exportFromJSON from "export-from-json";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Spin, message } from "antd";
import { client } from "../../../../utils/api-client";
import { Spinner } from "../../../lib";
import Legend from "./legend";
import Summary from "./summary";
import Filters from "./filters";
import CheckableTag from "antd/lib/tag/CheckableTag";

var roleOption = [
  {
    label: "AUTHOR",
    value: "AUTHOR",
  },
  {
    label: "REVIEWER",
    value: "REVIEWER",
  },
  {
    label: "REVIEWER-2",
    value: "REVIEWER-2",
  },
  {
    label: "UPLOADER",
    value: "UPLOADER",
  },
];

const TargetReport = ({ type }) => {
  const { run, isLoading, data, isError, error } = useAsync();
  const { user } = useAuth();
  const [departments, setDepartment] = useState([]);
  const [countData, setCountData] = useState([]);
  const [dep, setDep] = useState("");
  const [users, setUsers] = useState([]);
  const [roleData, setRole] = useState("");
  const [fromDate, setFrom] = useState(new Date());
  const [toDate, setTo] = useState(new Date());
  const [rec, setRec] = useState([]);
  const [managers, setManagers] = useState([]);
  const [project, setProject] = useState("");
  const [allProject, setProjectsData] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [form] = Form.useForm();
  const [projects, setProjects] = useState([]);
  const [totalDelta, setTotalDelta] = useState(0);
  const [payloadData, setPayloadData] = useState({});

  const submitData = async (val) => {
    const roles = Array.isArray(roleData) ? roleData : [roleData];
    const payload = { ...val, roleData: roles };
    if (user.roleData.includes("MANAGER") && type === "team") {
      payload.manager = user._id;
    }
    if (
      !user.roleData.includes("ADMIN") &&
      !user.roleData.includes("MANAGER")
    ) {
      payload.user = user.email;
      type = "user";
      payload.fromDate = payloadData.fromDate;
      payload.toDate = payloadData.toDate;
    }

    run(
      client("user/target/report", {
        body: { ...payload, type },
        method: "POST",
      })
    ).then((res) => {
      if (res.message === "Report generated successfully") {
        message.success(res.message);
      } else {
        message.error(res.message);
      }
    });
  };
  const [allRole, setAllRole] = useState(
    user.roleData.map((e) => ({ label: e, value: e }))
  );
  const allowedRoles = ["ADMIN", "MANAGER"];
  const isAdmin = user.roleData.some((role) => allowedRoles.includes(role));
  useEffect(() => {
    if (data?.message === "Fetched departments") {
      setDepartment(data?.departments);
    } else if (data?.message === "Fetched Report Users") {
      setUsers(data?.users);
    } else if (data?.message === "Report generated successfully") {
      setRec(data?.records);
      setProjectsData(data?.projectData);
      setCountData(data?.count);
      const totalD = data.records.reduce(
        (acc, record) => acc + parseFloat(record.delta),
        0
      );
      setTotalDelta(totalD);
    } else if (data?.message === "Fetched managers") {
      setManagers(data.data);
    }
  }, [data]);
  const fetchDepartments = async () => {
    await run(client("department/all/report"));
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const data = await run(
        client("user/target/report/fetch/project", {
          method: "GET",
        })
      );
      const arr = Object.values(data);
      if (Array.isArray(arr[1])) {
        setProjects(arr[1]);
      } else {
        setProjects([]);
      }
    };

    fetchProjects();
  }, [run]);

  const fetchManagers = async () => await run(client("admin/managers"));

  const fetchUsers = async (role, department) => {
    await run(client(`target/project/report/${department}/${role}`));
  };

  useEffect(() => {
    fetchDepartments();
    if (type === "team") {
      fetchManagers();
    }
  }, []);

  const setDepChange = (e) => {
    setDep(e);
    if (roleData !== "") {
      fetchUsers(roleData, e);
    }
  };

  const setRoleChange = (e) => {
    setRole(e);
    if (dep !== "") {
      fetchUsers(e, dep);
    }
  };

  useEffect(() => {
    if (rec.length > 0) {
      setCols(rec);
    }
  }, [rec]);

  const setCols = (rows) => {
    if (rows.length === 0) {
      setColumns([]);
      return;
    }
    const desiredColumns = [
      "dateKey",
      "modId",
      "projects",
      "totalProductivityHours",
      "Qualitative Task ",
      "Demand Hours",
      "Ticket Demand",
      "Ticket Created",
      "Delta",
    ];
    const cols = desiredColumns.map((key) => ({
      title: key.charAt(0).toUpperCase() + key.slice(1).replace("_", "  "),
      dataIndex: key,
      key: key,
    }));

    setColumns(cols);
  };
  const preprocessData = (data) => {
    const processedData = [];

    data.forEach((item) => {
      const existingItemIndex = processedData.findIndex(
        (processedItem) => processedItem.dateKey === item.dateKey
      );

      if (existingItemIndex === -1) {
        processedData.push({
          dateKey: item.dateKey,
          modId: item.modId,
          projects: item.projects,
          totalProductivityHours: item.totalProductivityHours,
          demandHours: item.demandHours,
          ticketDemand: item.ticketDemand,
          ticketused: item.ticketused,
          leaveHours: item.leaveHours,
          qualitativeRecords: item.qualitativeHours,
          delta: item.delta,
          totalDemandHours: item.totalDemandHours,
          color: item.color,
        });
      } else {
        processedData[existingItemIndex].projects.push(...item.projects);
        // Calculate total productivity for combined projects
        processedData[existingItemIndex].totalProductivityHours += parseFloat(
          item.totalProductivityHours
        );
      }
    });

    return processedData;
  };
  const preprocessedData = preprocessData(rec);
  const defaultExpandedRowKeys = preprocessedData.map((item) => item.dateKey);
  const handlePrint = () => {
    const dataToDownload = [];
    preprocessedData.forEach((record) => {
      dataToDownload.push({
        Date: record.dateKey,
        "Total Productivity Hours": record.totalProductivityHours,
        "Shift Hours": record.demandHours,
        "Ticket Hours": record.ticketDemand,
        "Leave Hours": record.leaveHours,
        totalDemandHours: record.totalDemandHours,
        Delta: record.delta,
      });
      record.projects.forEach((project) => {
        dataToDownload.push({
          Date: record.dateKey,
          modId: project.modId,
          "Project Name": project.projectName,
          Subproject: project.subProject,
          Department: project.department,
          Subject: project.subject,
          Done: project.done,
          Target: project.target,
          shiftHours: project.demandHours,

          "Productivity Hours": project.productivityHours,
        });
      });
    });
    const fileName = "table_data";
    const exportType = "xls";
    exportFromJSON({ data: dataToDownload, fileName, exportType });
  };

  roleOption = isAdmin ? roleOption : allRole;
  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "grid",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginBottom: 0,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h1>Productivity Report</h1>
          {rec.length !== 0 && <div style={{ marginBottom: "1rem" }}></div>}
          <div>
            <Legend />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {" "}
          {rec.length !== 0 && isAdmin && (
            <>
              <Button icon={<DownloadOutlined />} onClick={() => handlePrint()}>
                Download Report
              </Button>

              {type === "department" && (
                <Button
                  onClick={() => setModal(true)}
                  style={{ marginLeft: "0.5rem" }}
                >
                  Count View
                </Button>
              )}
            </>
          )}
        </div>
        <Filters
          setDepChange={setDepChange}
          setRoleChange={setRoleChange}
          departments={departments}
          users={users}
          roleOption={roleOption}
          projects={projects}
          submitData={submitData}
          fetchUsers={fetchUsers}
          setPayloadData={setPayloadData}
          payloadData={payloadData}
          isAdmin={isAdmin}
          isLoading={isLoading}
        />
      </div>
      <Summary totalDelta={totalDelta} countData={countData} />
      <Table
        style={{
          border: "1px solid #e8e8e8",
          borderRadius: "5px",
          overflow: "hidden",
        }}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "even-row" : "odd-row"
        }
        pagination={false}
        bordered
        loading={usersLoading}
        dataSource={preprocessedData}
        columns={[
          {
            title: "Date",
            dataIndex: "dateKey",
            key: "dateKey",
            width: 100,
          },
          {
            title: "Total Productivity Hours",
            dataIndex: "totalProductivityHours",
            key: "totalProductivityHours",
            width: 100,
          },
          {
            title: "Demand Hours",
            dataIndex: "demandHours",
            key: "demandHours",
            width: 60,
          },
          {
            title: "Ticket Hours",
            dataIndex: "ticketDemand",
            key: "ticketDemand",
            width: 60,
            render: (text, record) => (
              <div style={{ backgroundColor: record.color, padding: "8px" }}>
                {text}
              </div>
            ),
          },
          {
            title: "Qualitative Task",
            dataIndex: "qualitativeRecords",
            key: "qualitativeRecords",
            width: 100,
          },
          {
            title: "Leave Hours",
            dataIndex: "leaveHours",

            key: "leaveHours",
            width: 60,
            render: (text, record) => (
              <div>
                {text && (
                  <div style={{ backgroundColor: "#FFC72C", padding: "8px" }}>
                    {text}
                  </div>
                )}
                {/* {text} */}
              </div>
            ),
          },
          {
            title: "Total Demand Hours",
            dataIndex: "totalDemandHours",
            dataIndex: "totalDemandHours",
            key: "ticketused",
            width: 100,
          },
          {
            title: "Delta",
            dataIndex: "delta",
            key: "delta",
            width: 60,
            render: (text, record) => (
              <div>
                {text < 0 ? (
                  <div style={{ backgroundColor: "#ffa78d", padding: "8px" }}>
                    {text}
                  </div>
                ) : (
                  <div style={{ backgroundColor: "#4da79d", padding: "8px" }}>
                    {text}
                  </div>
                )}
                {/* {text} */}
              </div>
            ),
          },
        ]}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Table
                columns={[
                  isAdmin
                    ? {
                        title: "Project Name",
                        dataIndex: "projectName",
                        key: "projectName",
                      }
                    : {
                        title: "Mod Id", // If not admin, show "Mod Id"
                        dataIndex: "modId",
                        key: "modId",
                      },
                  {
                    title: "Subproject",
                    dataIndex: "subProject",
                    key: "subProject",
                  },
                  {
                    title: "Department",
                    dataIndex: "department",
                    key: "department",
                  },
                  {
                    title: "Subject",
                    dataIndex: "subject",
                    key: "subject",
                  },
                  {
                    title: "Done",
                    dataIndex: "done",
                    key: "done",
                  },
                  {
                    title: "Target",
                    dataIndex: "target",
                    key: "target",
                  },
                  {
                    title: "Productivity Hours",
                    dataIndex: "productivityHours",
                    key: "productivityHours",
                  },
                ]}
                dataSource={record.projects}
                pagination={false}
                size="middle"
                rowKey={(row, index) => `${record.dateKey}-${index}`} // Unique key for sub-rows
              />
              <div
                style={{ float: "right", marginTop: "20px", marginRight: "5%" }}
              >
                <b>Total Productivity Hours: {record.totalProductivityHours}</b>
              </div>
            </>
          ),
          rowExpandable: (record) =>
            record.projects && record.projects.length > 0, // Only expandable if there are projects
        }}
        defaultExpandAllRows
        size="middle"
        rowKey="dateKey"
        defaultExpandedRowKeys={defaultExpandedRowKeys} // Expand all rows by default
      />
    </div>
  );
};

export default TargetReport;
