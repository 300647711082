import React, { useState } from "react";
import { Button, Modal, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { client } from "../../../utils/api-client";
import { useAsync } from "../../../utils/use-async";

const UploadExcelModal = ({ visible, onClose, fetchRecords }) => {
  const { isLoading, run, error } = useAsync();

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = async () => {
    if (fileList.length === 0) {
      message.error("Please select a file first!");
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append("qualitative", fileList[0]);

    run(
      client("qualitative/task/upload", {
        body: formData,
        type: "formData",
        methos: "POST",
      })
        .then((res) => {
          message.success(res.message);
          setUploading(false);
          setFileList([]);
          onClose();
          fetchRecords();
        })
        .catch((error) => {
          message.error(error.message);
        })
    );
  };

  const uploadProps = {
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
    onRemove: () => setFileList([]),
  };

  return (
    <Modal
      title="Upload Excel File"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose} disabled={uploading}>
          Cancel
        </Button>,
        <Button
          key="upload"
          type="primary"
          onClick={handleUpload}
          loading={uploading}
        >
          {uploading ? "Uploading..." : "Upload"}
        </Button>,
      ]}
    >
      <Upload
        {...uploadProps}
        accept=".xlsx"
        // beforeUpload={(file) => {
        //   const isXlsx = file.name.toLowerCase().endsWith(".xlsx"); // Check by file extension
        //   if (!isXlsx) {
        //     message.error("You can only upload XLSX files!");
        //   }
        //   return isXlsx; // If true, file will be added; if false, file will be rejected
        // }}
      >
        <Button icon={<UploadOutlined />}>Select File</Button>
      </Upload>
      {fileList.length > 0 && (
        <p style={{ marginTop: 10 }}>Selected File: {fileList[0].name}</p>
      )}
    </Modal>
  );
};

export default UploadExcelModal;
