import React, { useState } from "react";
import { Modal, DatePicker, Button, message } from "antd";
import moment from "moment";
import { client } from "../../../../../utils/api-client";
import { useAsync } from "../../../../../utils/use-async";

const SyncPrevDataModal = ({ visible, onClose }) => {
  const { run } = useAsync();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSyncPrevData = async () => {
    if (!fromDate || !toDate) {
      message.error("Please select both dates");
      return;
    }

    const formattedFromDate = moment(fromDate).format("YYYY-MM-DD");
    const formattedToDate = moment(toDate).format("YYYY-MM-DD");

    setLoading(true);

    run(
      client(`allocation/sync/id`, {
        method: "PUT",
        body: { from: formattedFromDate, to: formattedToDate },
      })
    ).then((res) => {
      message.success(res.message);
    });

    onClose();

    setLoading(false);
  };

  return (
    <Modal
      title="Sync Previous Data"
      visible={visible}
      onOk={handleSyncPrevData}
      onCancel={onClose}
      confirmLoading={loading}
      okButtonProps={{ disabled: !fromDate || !toDate }} 
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <DatePicker
          placeholder="From Date"
          onChange={(date) => setFromDate(date)}
          style={{ width: "48%" }}
        />
        <DatePicker
          placeholder="To Date"
          onChange={(date) => setToDate(date)}
          style={{ width: "48%" }}
        />
      </div>
    </Modal>
  );
};

export default SyncPrevDataModal;
