import React from "react";
import { Badge, Card, Typography, Row, Col } from "antd";

const { Text } = Typography;

const Summary = ({ totalDelta, countData }) => {
  const isPositive = totalDelta >= 0;
  const backgroundColor = isPositive ? "#1CAC78" : "#FFA07A";

  return (
    <Card
      size="small"
      style={{
        marginTop: "7px",
        padding: "7px",
      }}
    >
      <Row justify="space-between" align="middle">
        <Col>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Text strong>Total Rows:</Text>
            <Badge
              count={countData}
              showZero
              overflowCount={9999}
              style={{ backgroundColor: "#1890ff", padding: "4px 8px" }}
            />
          </div>
        </Col>

        {/* Total Delta */}
        <Col>
          <Card
            size="small"
            bordered={false}
            style={{
              backgroundColor,
              borderRadius: "4px",
              padding: "4px 10px",
              minWidth: "110px",
              textAlign: "center",
            }}
          >
            <Text strong style={{ color: "white" }}>
              Δ {totalDelta.toFixed(3)}
            </Text>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default Summary;
