import React, { useState, useEffect } from "react";
import "./allocate.css";
import Table from "../../../table";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Button, Popconfirm, message, Space, Modal, DatePicker } from "antd";
import AddProject from "../add-project";
import { Spinner } from "../../../lib";
import { useHistory } from "react-router-dom"
import AddAllocation from "../add-allocation";
import SyncPrevDataModal from "./dataSyncModal";
import { useAuth } from "../../../../context/auth-context";

const ProjectAllocations = () => {
  const {
    run,
    isLoading,
    data
  } = useAsync();

  let [users, setUsers] = useState([]);
  let [modal, setModal] = useState(false);
  let [usersLoading, setUsersLoading] = useState(false);
  let [allocationModel, setAllocationModal] = useState(false)
  let [prevDataModal, setPrevDataModal] = useState(false);
  const showPrevDataModal = () => setPrevDataModal(true);
  const {user} =useAuth()
  const isAdmin = user.roleData.includes("ADMIN");

  const history = useHistory()

  let [toDelete, setToDelete] = useState("");

  useEffect(() => {
    if (data?.message === "Deleted Projects") {
      message.success(data?.message);
      setToDelete("");
      fetchSections();
    }

    if (data?.message === "Fetched Projects") {
      setUsers(transformTableData(data.projects));
      setUsersLoading(false);
    }
  }, [data,allocationModel]);

  const handleDelete = (name) => {
    setToDelete(name);
    run(client(`project/delete`, { method: "DELETE", body: { name } }));
  };
 const handleSync =async()=>{
  await run(
    client(`dev/allocations/lowercase`, {
      
      method: "get",
    }).then((res) => {
      message.success(res.message);
      setModal(false);
    
    })
  );
  
 }
  const columns = [
    {
      title: "Project Name",
      dataIndex: "project_name",
      key: "project_name",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Added By",
      key: "x",
      render: (record) => (
        <>
        {/* //added by ayush */}
          {record?.email}
        </>
      ),
    },
    {
      title: "Actions",
      render: (record) => (
        <>
          <Space>
            <Button type="primary" onClick={() => history.push(`/projectAllocation/${record._id}`)}>Show Details</Button>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record?.project_name)}
            >
              <Button type="danger">
                Delete {isLoading && toDelete === record.name ? <Spinner /> : ""}
              </Button>
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  const transformTableData = (data) => {
    return data.map(({ addedBy: { email }, ...project }, index) => ({ key: index, ...project, email, sr: index + 1 }));
  };

  const fetchSections = () => {
    run(client(`allocation/projects`));
    setUsersLoading(true);
  };

  useEffect(() => {
    fetchSections();
  }, []);

  const handleCancel = (e) => {
    setModal(false);
  };

  const handleCancelAllocation = (e) => {
    setAllocationModal(false);
  }

  const showModal = () => setModal(true);

  const showModalAllocation = () => setAllocationModal(true);


  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>Add Projects</h1>
        <div>

      {isAdmin &&  <Button style={{ marginLeft: "10px" }} type="danger" onClick={showPrevDataModal}>
             Sync Prev Data
                </Button>}

        <Button type="ghost" onClick={showModal} style={{marginRight:'1rem'}}>
          Add Project
        </Button>
          <Button
            type="ghost"
            onClick={showModalAllocation}
          >
            Upload Allocations IDs
          </Button>
          <Button
          style={{marginLeft:"10px"}}
            type="danger"
            onClick={handleSync}
          >
            Sync Small Case
          </Button>
        </div>
      </div>
      <Table
        loading={usersLoading}
        columns={columns}
        data={users}
        size="middle"
      />
      <AddProject
        modal={modal}
        handleCancel={handleCancel}
        fetchSections={fetchSections}
        setModal={setModal}
      />
      <AddAllocation
        modal={allocationModel}
        handleCancel={handleCancelAllocation}
        setModal={setAllocationModal} />
        
      <SyncPrevDataModal visible={prevDataModal} onClose={() => setPrevDataModal(false)} />

    </div>
  );
};

export default ProjectAllocations;
