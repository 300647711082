import React from "react";
import { Button, DatePicker, Input, Form, Row, Col, Space } from "antd";
import {
  SearchOutlined,
  SyncOutlined,
  CalendarOutlined,
  UserOutlined,
  MailOutlined,
} from "@ant-design/icons";

const { RangePicker } = DatePicker;

const Filters = ({ onFilter }) => {
  const [form] = Form.useForm();

  const handleSearch = (values) => {
    const filters = {
      startDate: values.dateRange?.[0]?.format("YYYY-MM-DD"),
      endDate: values.dateRange?.[1]?.format("YYYY-MM-DD"),
      email: values.email,
      name: values.name,
    };
    onFilter(filters);
  };

  const handleReset = () => {
    form.resetFields();
    onFilter({});
  };

  return (
    <div
      style={{
        background: "#ffffff",
        padding: "20px",
        borderRadius: "8px",
        marginBottom: "24px",
      }}
    >
      <Form form={form} layout="vertical" onFinish={handleSearch}>
        <Row gutter={[24, 16]} align="bottom">
          {/* Date Range */}
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={
                <span style={{ fontWeight: 500, color: "#1f1f1f" }}>
                  Date Range
                </span>
              }
              name="dateRange"
            >
              <RangePicker
                style={{ width: "100%" }}
                suffixIcon={<CalendarOutlined style={{ color: "#8c8c8c" }} />}
              />
            </Form.Item>
          </Col>

          {/* Email */}
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={
                <span style={{ fontWeight: 500, color: "#1f1f1f" }}>Email</span>
              }
              name="email"
            >
              <Input
                placeholder="Search by email"
                allowClear
                prefix={<MailOutlined style={{ color: "#8c8c8c" }} />}
                onPressEnter={() => form.submit()}
              />
            </Form.Item>
          </Col>

          {/* Name */}
          <Col xs={24} sm={24} md={12} lg={8} xl={6}></Col>

          {/* Actions */}
          <Col xs={24} sm={24} md={24} lg={24} xl={6}>
            <div
              style={{
                display: "flex",
                gap: "12px",
                justifyContent: "flex-end",
                marginTop: "8px",
              }}
            >
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
                style={{
                  height: "40px",
                  padding: "0 24px",
                  fontWeight: 500,
                  borderRadius: "6px",
                }}
              >
                Apply Filters
              </Button>
              <Button
                icon={<SyncOutlined />}
                onClick={handleReset}
                style={{
                  height: "40px",
                  padding: "0 24px",
                  fontWeight: 500,
                  border: "1px solid #d9d9d9",
                  borderRadius: "6px",
                }}
              >
                Reset
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Filters;
