import React from "react";
import { Button, Form, Input, Select } from "antd";
import { Spinner } from "../../../lib";

const Filters = ({
  setDepChange,
  setRoleChange,
  departments,
  users,
  roleOption,
  projects,
  submitData,
  setPayloadData,
  payloadData,
  isAdmin,
  isLoading,
}) => {
  const [form] = Form.useForm();

  const handleDateRange = () => {
    const today = new Date();
    const from = new Date(today.getFullYear(), today.getMonth(), 2);
    const to = new Date(today);
    to.setDate(to.getDate() + 1);

    setPayloadData({
      fromDate: from.toISOString().split("T")[0],
      toDate: to.toISOString().split("T")[0],
    });
  };

  return (
    <Form
      form={form}
      onFinish={submitData}
      layout="inline"
      className="filters-container"
      style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}
    >
      <Form.Item label="Department" name="dep" style={{ minWidth: 200 }}>
        <Select
          placeholder="Select Department"
          onChange={setDepChange}
          style={{ width: "100%" }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {departments.map((dep) => (
            <Select.Option key={dep._id} value={dep._id}>
              {dep.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Role" name="roleData" style={{ minWidth: 200 }}>
        <Select
          placeholder="Select Role"
          onChange={setRoleChange}
          style={{ width: "100%" }}
        >
          {roleOption.map((role) => (
            <Select.Option key={role.value} value={role.value}>
              {role.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {isAdmin && (
        <Form.Item label="User" name="user" style={{ minWidth: 220 }}>
          <Select
            placeholder="Select User"
            style={{ width: "100%" }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {users.map((user) => (
              <Select.Option key={user._id} value={user.email}>
                {user.email}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {isAdmin ? (
        <>
          <Form.Item label="From" name="fromDate" style={{ minWidth: 180 }}>
            <Input
              type="date"
              value={payloadData.fromDate}
              onChange={(e) =>
                setPayloadData({ ...payloadData, fromDate: e.target.value })
              }
            />
          </Form.Item>

          <Form.Item label="To" name="toDate" style={{ minWidth: 180 }}>
            <Input
              type="date"
              value={payloadData.toDate}
              onChange={(e) =>
                setPayloadData({ ...payloadData, toDate: e.target.value })
              }
            />
          </Form.Item>
        </>
      ) : (
        <Form.Item>
          <Button type="default" onClick={handleDateRange}>
            Current Month
          </Button>
        </Form.Item>
      )}

      {isAdmin && (
        <Form.Item label="Project" name="project" style={{ minWidth: 220 }}>
          <Select placeholder="Select Project" style={{ width: "100%" }}>
            {projects.map((project) => (
              <Select.Option key={project} value={project}>
                {project}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {isLoading ? <Spinner /> : "View Report"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Filters;
