import React from "react";
import { Typography, Space, Row } from "antd";

const { Text } = Typography;

const legendItems = [
  { color: "#85c6ff", label: "Working Days" },
  { color: "#FFA07A", label: "Non working day" },
  { color: "#fa9e95", label: "Ticket Used" },
  { color: "#c1f6f7", label: "Ticket Created" },
  { color: "#FFC72C", label: "Leave" },
  { color: "#1CAC78", label: "Incentive" },
  { color: "#FFA07A", label: "Loss" },
];

const Legend = () => (
  <Row wrap={false} gutter={[16, 16]} align="middle">
    <Space size="large" wrap>
      {legendItems.map((item, index) => (
        <Space key={index} align="center">
          <div
            style={{
              width: "16px",
              height: "16px",
              backgroundColor: item.color,
              borderRadius: "4px",
            }}
          />
          <Text>{item.label}</Text>
        </Space>
      ))}
    </Space>
  </Row>
);

export default Legend;
