import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Select,
  Typography,
  Card,
  Divider,
  message,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { client } from "../../../utils/api-client";
import { useAsync } from "../../../utils/use-async";

const { Option } = Select;
const { Title, Text } = Typography;

const TABS = [
  { name: "Manage Projects", key: "projects" },
  { name: "Manage File", key: "manage-file" },
  { name: "Manage Users", key: "users" },
  { name: "Manage Targets", key: "targets" },
  { name: "Worklog", key: "worklog" },
  { name: "Recruitment", key: "recruitment" },
  { name: "Production Report", key: "target-report" },
  { name: "Timesheet", key: "timesheet/users" },
  { name: "Allocation ID", key: "projectAllocations" },
  { name: "Status Update", key: "status-report" },
];

const AccessControl = () => {
  const { isLoading, run, isError, error, data } = useAsync();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [users, setUsers] = useState([]);
  const [modalType, setModalType] = useState("add"); // 'add' or 'remove'
  const [isProcessing, setIsProcessing] = useState(false);
  const [tabsforUser, setTabsforUser] = useState([]);
  const fetchUsers=()=>{
    if (users.length === 0) {
      run(client(`/get/allUsers`));
    }
  }
  useEffect(() => {
    fetchUsers()
  }, []);

  useEffect(() => {
    if (data?.messages === "Users found") {
      setUsers(data.users);
    }
  }, [data]);

  const showModal = (user, type) => {
    setSelectedUser(user);
    setModalType(type);

    if (type === "remove") {
      setTabsforUser(user?.accessData[0] || []);
    } else {
      setTabsforUser([]);
    }

    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsProcessing(true);
    const accessTabs = selectedTabs
      .map((tabKey) => {
        const tab = TABS.find((t) => t.key === tabKey);
        return tab ? { name: tab.name, key: tab.key, keys: [] } : null;
      })
      .filter(Boolean);

    const body = {
      user: selectedUser._id,
      access: accessTabs,
    };

    try {
      if (modalType === "remove") {
        await run(client(`remove/tab/access`, { method: "PUT", body }));
        message.success("Access removed successfully!");
      } else {
        await run(client(`/update/access`, { method: "PUT", body }));
        message.success("Access updated successfully!");
      }
      setIsModalVisible(false);
    } catch (err) {
      message.error("Failed to update access.");
    } finally {
      setIsProcessing(false);
      fetchUsers()

    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text strong>{text}</Text>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Access Control",
      key: "access",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showModal(record, "add")}
          >
            Add Access
          </Button>
          <Button
            type="default"
            danger
            icon={<MinusOutlined />}
            onClick={() => showModal(record, "remove")}
          >
            Remove Access
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Card style={{ maxWidth: "100%", margin: "20px auto", padding: "20px" }}>
      <Title level={2} style={{ textAlign: "center", marginBottom: "20px" }}>
        Access Control Management
      </Title>
      <Table
        dataSource={users}
        columns={columns}
        rowKey="_id"
        bordered
        pagination={{ pageSize: 5 }}
      />

      <Modal
        title={
          <Title level={4}>
            {modalType === "add" ? "Add Access" : "Remove Access"} for{" "}
            <Text type="secondary">{selectedUser?.email}</Text>
          </Title>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={isProcessing ? "Processing..." : "Confirm"}
        cancelText="Cancel"
        okButtonProps={{ disabled: isProcessing }}
        maskClosable={false} // Prevents closing when clicking outside
      >
        <Divider />
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder={`Select tabs to ${
            modalType === "add" ? "add" : "remove"
          }`}
          value={selectedTabs}
          onChange={setSelectedTabs}
        >
          {modalType === "add"
            ? TABS.filter(
                (tab) =>
                  !selectedUser?.accessData?.some(
                    (access) => access.key === tab.key
                  )
              ).map((tab) => (
                <Option key={tab.key} value={tab.key}>
                  {tab.name}
                </Option>
              ))
            : tabsforUser?.accessTabs?.map((accessTab) => (
                <Option key={accessTab?.key} value={accessTab?.key}>
                  {accessTab?.name}
                </Option>
              ))}
        </Select>
      </Modal>
    </Card>
  );
};

export default AccessControl;
