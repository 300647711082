import React, { useState, useEffect, useCallback } from "react";
import "./qualitative.css";
import Table from "../../table";
import { useAuth } from "../../../context/auth-context";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { Button, Form, Input, Popconfirm, Space, message } from "antd";
import { useParams, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import AddQualitative from "../qualitative-add";
import TaskList from "../qualitative-task-list";
import EditList from "../qualitative-edit";
import exportFromJSON from "export-from-json";
import UploadAllocations from "../allocations/upload-allocations";
import UploadExcelModal from "./upload-task";
import Filters from "./filters";
import DownloadOutlined from "@ant-design/icons/DownloadOutlined";
import UploadOutlined from "@ant-design/icons/UploadOutlined";
const moment = require("moment-timezone");

const AllQualitativeDataForAdmin = () => {
  const { run, isLoading, data, isError, error } = useAsync();

  const [usersLoading, setUsersLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [form] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [taskModal, setTaskModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [editList, setEditList] = useState([]);
  const [userId, setUserid] = useState({});
  const [uploadModal, setUploadModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(500);
  const handleUpload = () => {
    setUploadModal(true);
  };
  const approveRecord = async (val) => {
    run(client("qualitative/tasks/approve", { body: val, method: "PUT" })).then(
      (res) => {
        if (res.success) {
          fetchRecords();
        }
      }
    );
  };

  const columns = [
    {
      title: "Effective Date",
      dataIndex: "effectiveDate",
      key: "effectiveDate",
      width: 100,
      sorter: true,
      ellipsis: true,
      render: (record) => <>{record.split("T")[0]}</>,
    },
    {
      title: "Approval Status",
      width: 100,
      sorter: true,
      ellipsis: true,
      render: (record) => (
        <>
          {record.managerApproval.toString() === "false"
            ? "Pending"
            : "Approved"}
        </>
      ),
    },
    // added by ayush
    {
      title: "Total Time",
      dataIndex: "totalHours",
      key: "totalHours",

      width: 100,
      sorter: true,
      ellipsis: true,
      render: (record) => (
        <>
          <div style={{ whiteSpace: "pre-wrap" }}>{record}</div>
        </>
      ),
    },
    {
      title: "User",
      width: 100,
      sorter: true,
      ellipsis: true,
      render: (record) => <>{record?.addedBy?.email}</>,
    },
    {
      title: "Actions",
      width: 200,
      ellipsis: true,
      render: (record) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setTaskModal(true);
              setTaskList(record?.taskList);
              setUserid(record?._id);
            }}
          >
            View Task List
          </Button>

          {/* <Button type="link" onClick={() => {
            setEditModal(true);
            setEditList(record)
          }}>Edit Task List
          </Button>
          
            <Popconfirm
              title="Sure to approve?"
              onConfirm={() => {
                approveRecord(record)
              }
              }
            >
              <Button type="link" >
                Approve Task
              </Button>
            </Popconfirm> */}
        </>
      ),
    },
  ];

  const fetchRecords = useCallback(() => {
    const queryString = new URLSearchParams({
      page,
      pageSize,
      ...(filters.startDate && { startDate: filters.startDate }),
      ...(filters.endDate && { endDate: filters.endDate }),
      ...(filters.email && { email: filters.email }),
    }).toString();
    const url = `qualitatives/tasks/all/admin?${queryString}`;
    run(client(url, { method: "GET" })).then((res) => {
      setUsersLoading(true);
    });
  }, [run, filters, page, pageSize]);

  useEffect(() => {
    if (data?.message === "Fetched All Qualiatative Task") {
      setRecords(transformTableData(data?.qualitativeRecords));
      setUsersLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      message.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);
  const transformTableData = (data) =>
    data.map((user, index) => {
      let { ...rest } = user;

      return {
        key: index,
        ...rest,
        sr: index + 1,
      };
    });
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
    console.log(pagination);
  };
  const handlePrint = () => {
    const fileName = "Qualitative_Task";
    const exportType = "xls";
    const dataToExport = data?.qualitativeRecords?.map((record) => {
      const tasksFormatted = record?.taskList
        .map((task) => {
          return `${task?.title} (${task?.timeSpent?.hours}h ${task?.timeSpent?.minutes}m)`;
        })
        .join(", ");

      return {
        "Effective Date": new Date(record.effectiveDate).toLocaleDateString(),
        "Approval Status": record.managerApproval ? "Approved" : "Pending",
        "Total Time": record.totalHours,
        User: record?.addedBy?.email,
        "Task Details": tasksFormatted,
      };
    });
    exportFromJSON({ data: dataToExport, fileName, exportType });
  };
  return (
    <div className="manage--wrapper" style={{ padding: "24px" }}>
      {/* Header Section */}
      <div
        style={{
          marginBottom: "24px",
          display: "flex",
          flexWrap: "wrap",
          gap: "16px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1
          style={{
            margin: 0,
            fontSize: "24px",
            fontWeight: 600,
            color: "#1f1f1f",
          }}
        >
          All Qualitative Projects
        </h1>

        {/* Filters and Actions Container */}
        <div
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div style={{ flex: 1, minWidth: "300px" }}>
            <Filters onFilter={setFilters} />
          </div>

          <Space>
            <Button
              type="primary"
              onClick={() => handlePrint()}
              icon={<DownloadOutlined />}
              style={{
                background: "#1890ff",
                borderColor: "#1890ff",
                fontWeight: 500,
              }}
            >
              Download Tasks
            </Button>
            <Button
              onClick={() => handleUpload()}
              icon={<UploadOutlined />}
              style={{
                borderColor: "#d9d9d9",
                fontWeight: 500,
              }}
            >
              Upload Tasks
            </Button>
          </Space>
        </div>
      </div>

      {/* Table Section */}
      <Table
        loading={usersLoading}
        data={records}
        columns={columns}
        size="middle"
        bordered
        scroll={{ x: "max-content" }}
        pagination={{
          current: page,
          pageSize: pageSize,
          total: data?.totalRecords || 0,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100", "200", "500", "1000"],
          showTotal: (total) => `Total ${total} items`,
          style: { marginRight: "16px" },
        }}
        onChange={handleTableChange}
        rowClassName={() => "ant-table-row"}
        style={{
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        }}
      />

      {/* Modals */}
      <TaskList
        modal={taskModal}
        setModal={setTaskModal}
        records={taskList}
        userId={userId}
      />
      <UploadExcelModal
        visible={uploadModal}
        onClose={() => setUploadModal(false)}
        fetchRecords={fetchRecords}
      />
    </div>
  );
};

export default AllQualitativeDataForAdmin;
